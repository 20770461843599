import axios from '@/axios.js'

export default {
  fetchPaymentStatuses ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('payment-status')
        .then(({data: response}) => {
          commit('SET_PAYMENT_STATUSES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchPaymentStatus (context, paymentStatusID) {
    return new Promise((resolve, reject) => {
      axios.get(`payment-status/${paymentStatusID}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storePaymentStatus (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('payment-status', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updatePaymentStatus (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`payment-status/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyPaymentStatus ({ commit }, paymentStatusID) {
    return new Promise((resolve, reject) => {
      axios.delete(`payment-status/${paymentStatusID}`)
        .then((response) => {
          commit('REMOVE_RECORD', paymentStatusID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
